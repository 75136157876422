import { registerLocaleData } from '@angular/common';
import { Injectable, LOCALE_ID, inject, provideAppInitializer } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { getInitialLoginContext } from '@zvoove-sso/shared';

@Injectable({
  providedIn: 'root',
})
class I18n {
  locale = 'de-DE';

  async setLocale() {
    this.locale = getInitialLoginContext()?.language ?? 'de-DE';
    if (navigator.language.startsWith('en')) {
      // set to en if browser prefers en
      this.locale = 'en-GB';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let localeModule: any;
    let coreMsgFile: string;
    let localeTranslationsModule: { default: { locale: string; translations: Record<string, string> } };
    if (this.locale.startsWith('en')) {
      localeModule = await import(`@angular/common/locales/en-GB`);
      coreMsgFile = 'en-GB';
      localeTranslationsModule = await import('../../assets/resource/messages.en-GB.json');
    } else {
      localeModule = await import(`@angular/common/locales/de`);
      coreMsgFile = 'de-DE';
      localeTranslationsModule = await import('../../assets/resource/messages.de-DE.json');
    }
    this.locale = coreMsgFile;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    registerLocaleData(localeModule.default);
    loadTranslations(localeTranslationsModule.default.translations);
  }
}

const setLocale = () =>
  provideAppInitializer(() => {
    const initializerFn = (
      (i18n: I18n) => () =>
        i18n.setLocale()
    )(inject(I18n));
    return initializerFn();
  });

const setLocaleId = () => ({
  provide: LOCALE_ID,
  useFactory: (i18n: I18n) => i18n.locale,
  deps: [I18n],
});

export const i18nModule = {
  setLocale: setLocale,
  setLocaleId: setLocaleId,
};
